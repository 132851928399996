import React from "react";
import AmountWidget from "./AmountWidget";
import { noncirculatingSupply } from "../common/lumens.js";

export default class LumensNonCirculating extends AmountWidget {
  componentDidMount() {
    this.timerID = setInterval(() => this.updateAmount(), 60 * 60 * 1000);
    this.updateAmount();
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  updateAmount() {
    noncirculatingSupply().then((amount) => {
      this.setState({ amount, code: "XBN", loading: false });
    });
  }

  renderName() {
    return (
      <div>
        <span>Non-Circulating Supply</span>
      </div>
    );
  }
}
