export const knownAccounts = {
  GCKX3XVTPVNFXQWLQCIBZX6OOPOIUT7FOAZVNOFCNEIXEZFRFSPNZKZT: "Coins base",
  GBUQWP3BOUZX34TOND2QV7QQ7K7VJTG6VSE7WMLBTMDJLLAW7YKGU6EP: "Coins iss.",
  GCGNWKCJ3KHRLPM3TM6N7D3W5YKDJFL6A2YCXFXNMRTZ4Q66MEMZ6FI2: "Poloniex",
  GA5XIGA5C7QTPTWXQHY6MCJRMTRZDOSHR6EFIBNDQTCQHG262N4GGKTM: "Kraken",
  GC2BQYBXFOVPRDH35D5HT2AFVCDGXJM5YVTAF5THFSAISYOWAJQKRESK: "Tempo base.",
  GAP5LETOV6YIE62YAM56STDANPRDO7ZFDBGSNHJQIYGGKSMOZAHOOS2S: "Tempo iss.",
  GD4CUDRD6YEG5LGFETZVTZ45THGEZX5UJHCFCGCIALK7PQNYD3S5ODS2: "Ernest",
  GB2Y4SUXOWSTXHTL7QVNGGK3Y6IOMUGHDB3ZICWJN2EJ2PJHORWS3LG4: "Btc38",
  GATEMHCCKCY67ZUCKTROYN24ZYT5GK4EQZ65JJLDHKHRUZI3EUEKMTCH: "NaoBTC",
  GB6YPGW5JFMMP2QB2USQ33EUWTXVL4ZT5ITUNCY3YKVWOJPP57CANOF3: "Bittrex",
  GAS3Z3TVBJNPAUU2W4J56M4XHX32T7GUBCWTNKETQDSSKEFLUO6IM2NL: "Bantu Issuing",
  GCVM6UG5CPFBDZLIPAXOKTYF5L7LDOPSOKWFT4D55AM4DRVKFX6BANTU: "Bantu Distribution",
  GCO2IP3MJNUOKS4PUDI4C7LGGMQDJGXG3COYX3WSB4HHNAHKYV5YL3VC: "Binance",
  GDCIUCGL7VEMMF6VYJOW75KQ5ZCLHAQBRM6EPFTKCRWUYVUOOYQCKC5A: {
    name: "Mobius.network",
    url: "https://mobius.network",
    icon:
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAICAYAAAAftBSpAAABp0lEQVR42mWRu2sUURTG5x+xjX0aIUgKbcTCRrBIihSCyIJIMEQsBNPkoUFcEWwkplCCgogsGIzBQIKIhKyiRHQX8zK7M7uZTOZxJzPXmXt/3h0NBv3gFPc8vu/c71iA7kSgtK4nuV6Lcy0yrQ/y/4bMtU5NXR/KWZ2HpxTlIOHKekSp6jO45DP9QeCKnKLdwAzz3kmZ+igYnfcpv/ax/ayoWa7pui9TrrkxQ7WIvsU9Tsy0OVa26R1v8sAMrLYklUbC1BfB7Xchwy88Tk/anLtp40U51mJL6kqQ4uWKIFPUDfuE2aR7tEnXpU2O9K/Tc32byTcBjTAjTBV7seJrQzL02KW28xPr1UqsT11tMP8p5oeX0TJfcOKMufo+Z+84dJ3f4OjlLbrHmvQ926WymVATGTWZ8yiRtI2w5QulL9x1OH5jm9JTl4m3IQ+/CZZ20oJw5XtKySj23HM4+aTNmYVdBlZDLrYFzzOJ8RmrcNywzZpNppcjZtf22TBrHxjagTkcVVtyqxox/DlkZCvmZfyboDD2z5kKFHf7O/sfOsSp0kgTh0V+ARai44A02yZhAAAAAElFTkSuQmCC",
  },
};
