import React from "react";
import axios from "axios";
import * as d3 from 'd3'
import BarChart from "react-d3-components/lib/BarChart";
// import * as Recharts from "recharts/umd/Recharts";
import clone from "lodash/clone";
import each from "lodash/each";

// const BarChart = Recharts.BarChart;
// const Bar = Recharts.Bar;
// const XAxis = Recharts.XAxis;
// const YAxis = Recharts.YAxis;
// const Tooltip = Recharts.Tooltip;
// const Legend = Recharts.Legend;
// const CartesianGrid = Recharts.CartesianGrid;

export default class FailedTransactionsChart extends React.Component {
  constructor(props) {
    super(props);
    this.panel = null;
    this.colorScale = d3.scale.category10();
    this.state = {
      loading: true,
      chartWidth: 400,
      chartHeigth: this.props.chartHeigth || 120,
    };
    this.url = `${this.props.horizonURL}/ledgers?order=desc&limit=${this.props.limit}`;
  }

  componentDidMount() {
    this.getLedgers();
    // Update chart width
    this.updateSize();
    setInterval(() => this.updateSize(), 5000);
  }

  updateSize() {
    let value = this.panel.offsetWidth - 20;
    if (this.state.chartWidth !== value) {
      this.setState({ chartWidth: value });
    }
  }

  onNewLedger(ledger) {
    let data = clone(this.state.data);
    data[0].values.push({
      x: ledger.sequence.toString(),
      y: ledger.successful_transaction_count,
    });
    data[1].values.push({
      x: ledger.sequence.toString(),
      y: ledger.failed_transaction_count,
    });
    data[0].values.shift();
    data[1].values.shift();
    this.setState({ loading: false, data });
  }

  getLedgers() {
    axios.get(this.url).then((response) => {
      let data = [
        {
          label: "Success",
          values: [],
        },
        {
          label: "Fail",
          values: [],
        },
      ];
      each(response.data._embedded.records, (ledger) => {
        data[0].values.unshift({
          x: ledger.sequence.toString(),
          y: ledger.successful_transaction_count,
        });
        data[1].values.unshift({
          x: ledger.sequence.toString(),
          y: ledger.failed_transaction_count,
        });
      });
      this.setState({ loading: false, data });
      // Start listening to events
      this.props.emitter.addListener(
        this.props.newLedgerEventName,
        this.onNewLedger.bind(this),
      );
    });
  }

  render() {
    return (
      <div
        ref={(el) => {
          this.panel = el;
        }}
      >
        <div className="row">
          <div className="col-lg-12">
            <div className="card py-2 border-0 shadow-lg">
              <div className="card-body">
                <h4 className="header-title">
                  <span style={{ borderBottom: "2px solid #1f77b4" }}>
                    Successful
                  </span>{" "}
                  &amp;{" "}
                  <span style={{ borderBottom: "2px solid #ff7f0e" }}>
                    Failed
                  </span>{" "}
                  Txs in the last {this.props.limit} ledgers{" "}
                  {this.props.network}
                  <a href={this.url} target="_blank" rel="noopener noreferrer" className="api-link">
                    API
                  </a>
                </h4>
                <div className="mt-4 chartjs-chart">
                  {this.state.loading ? (
                    "Loading..."
                  ) : (
                  //   <BarChart
                  //   width={this.state.chartWidth}
                  //   height={this.state.chartHeigth}
                  //   data={this.state.data[0].values}
                  //   margin={{ top: 10, bottom: 8, left: 50, right: 10 }}
                  //   barSize={5}
                  //   key={Math.random()}
                    
                  // >
                  //   <CartesianGrid strokeDasharray="3 3" />
                  //   <XAxis dataKey="name" interval={10} axisLine={false} />
                  //   <YAxis dataKey="y" axisLine={false} />
                  //   <Tooltip />
                  //   <Legend />
                  //   <Bar dataKey="y" stackId="y" fill="#82ba9c" isAnimationActive={false} />
                  //   <Bar dataKey="y" stackId="y" fill="#8884d8" isAnimationActive={false} />
      
                  // </BarChart>
                    <BarChart
                      tickFormat={d3.format("d")}
                      data={this.state.data}
                      width={this.state.chartWidth}
                      colorScale={this.colorScale}
                      height={this.state.chartHeigth}
                      margin={{ top: 10, bottom: 8, left: 50, right: 10 }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
