import React from "react";

export default class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 footer-side">
              <a className="orange-text" target="_blank"
                  rel="noopener noreferrer" href="https://bantufoundation.org">
                Bantu Blockchain Foundation
              </a>
            </div>
            <div className="col-md-6">
              <div className="text-md-right footer-links d-sm-block">
                <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/bantublockchain">
                  <i className="fa fa-twitter social-icons"></i>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/bantublockchain"
                >
                  <i className="fa fa-facebook social-icons"></i>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/bantublockchain"
                >
                  <i className="fa fa-linkedin social-icons"></i>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/bantublockchain"
                >
                  <i className="fa fa-instagram social-icons"></i>
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://github.com/bantublockchain">
                  <i className="fa fa-github social-icons"></i>
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://medium.com/@bantublockchain">
                  <i className="fa fa-medium social-icons"></i>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.reddit.com/r/bantublockchain"
                >
                  <i className="fa fa-reddit social-icons"></i>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.youtube.com/channel/UCXP0KYKl1jLG-0QbeMH86IA"
                >
                  <i className="fa fa-youtube social-icons"></i>
                </a>
                <a target="_blank" rel="noopener noreferrer" href="https://t.me/bantublockchain">
                  <i className="fa fa-telegram social-icons"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
