import React from "react";
import AmountWidget from "./AmountWidget";
import { circulatingSupply } from "../common/lumens.js";

export default class LumensCirculating extends AmountWidget {
  componentDidMount() {
    this.timerID = setInterval(() => this.updateAmount(), 60 * 60 * 1000);
    this.updateAmount();
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  updateAmount() {
    circulatingSupply().then((amount) => {
      this.setState({
        amount: amount,
        code: "XBN",
        loading: false,
      });
    });
  }

  renderName() {
    return (
      <div>
        <span>Circulating Supply</span>
      </div>
    );
  }
}
