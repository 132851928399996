// import "./style.scss";
import React from "react";

export default class DarkModeToggle extends React.Component {
  constructor(props) {
    super(props);

    if (JSON.parse(localStorage.getItem("DARK_MODE")) === true) {
      document.body.classList.add("dark-mode");
    }

    this.state = {
      darkMode: JSON.parse(localStorage.getItem("DARK_MODE")),
    };

    this.handleModeChange = this.handleModeChange.bind(this);
  }

  handleModeChange() {
    if (!this.state.darkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }

    this.setState({
      darkMode: !this.state.darkMode,
    });
    localStorage.setItem("DARK_MODE", !this.state.darkMode);
  }

  render() {
    return (
      <div className="mode-btn ml-auto">
        <button
          className="btn btn-lg d-none d-md-block float-right btn-outline-dark"
          onClick={this.handleModeChange}
        >
          Switch Mode
        </button>
        <button
          className="btn btn-sm d-sm-block d-md-none btn-outline-dark"
          onClick={this.handleModeChange}
        >
          Switch Mode
        </button>
      </div>
    );
  }
}
