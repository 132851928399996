import React from "react";
import BigNumber from "bignumber.js";

export default class AmountWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  renderName() {
    return null;
  }

  render() {
    let amountBig;
    let amount;
    if (this.state.loading) {
      amountBig = "Loading...";
    } else {
      if (this.state.amount >= 1000000000) {
        amountBig = Math.floor(this.state.amount / 10000000) / 100 + "B";
      } else if (this.state.amount >= 1000000) {
        amountBig = Math.floor(this.state.amount / 10000) / 100 + "M";
      } else if (this.state.amount < 1000000 && this.state.amount >= 100000) {
        amountBig = Math.floor(this.state.amount / 1000) + "k";
      } else {
        amountBig = Math.floor(this.state.amount);
      }

      if (this.state.code) {
        amountBig += ` ${this.state.code}`;
      }

      amount = new BigNumber(this.state.amount).toFormat(7);
    }

    return (
      <div className="row">
        <div className="w-100 px-3">
          <div className="widget-simple  card py-5 border-0 shadow-lg">
            <div className=" text-mutedd font-weight-normal mt-5 mb-3 text-truncate">
              {this.renderName()}
            </div>
            <div className="card-body">
              <div className="text-orange mt-0 text-center">
                <span className="mt-3 mb-3">{amountBig}</span>
              </div>
              <p className="text-muted mb-0 text-center mb-5">
                {this.state.loading ? (
                  ""
                ) : (
                  <span>
                    {amount} {this.state.code}
                  </span>
                )}{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
