import React from "react";
import logo from "../images/bantu-logo.png";
import DarkModeToggle from "./DarkModeToggle";

export default class AppBar extends React.Component {
  render() {
    return (
      <div className="topnav ">
        <div className="container-fluid">
          {/* <!-- LOGO --> */}
          <div className="row nav-row-padding">
            <div className="logo-box col-sm-6 d-none d-md-block">
              <a
                href="https://dashboard.bantu.network/"
                className="logo logo-dark text-left"
              >
                <span className="logo-lg">
                  <img src={logo} alt="bantu logo" height='41' width='41' />
                  <span className="so-logo__separator">|</span>
                  <span className="  brand-text">
                    Bantu Network Dashboard
                  </span>
                </span>
              </a>
            </div>
            <div className="logo-box col-sm-6 d-sm-block d-md-none mr-3" style={{marginLeft: '-15px'}}>
              <a
                href="https://dashboard.bantu.network/"
                className="logo logo-dark text-left"
              >
                <span className="logo-lg">
                  <img src={logo} alt="bantu logo" height='41' width='41'/>
                  <span className="so-logo__separator">|</span>
                  <span className="  brand-text">
                    Bantu Network Dashboard
                  </span>
                </span>
              </a>
            </div>
            <div className="col-sm-6 d-none d-md-block">
              <DarkModeToggle />
            </div>
            <div className="col-sm-6 d-sm-block d-md-none pl-5 " style={{marginTop: '-60px', marginRight: '-70px !important'}}>
              <DarkModeToggle />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

